// Add ServiceGun from DeviceCache Response//

export enum DeviceCacheServiceGunEnum {
  DSSConfiguration = 'com.hp.cdm.service.security.version.1.resource.dssConfig',
  SnmpConfiguration = 'com.hp.cdm.service.network.version.1.resource.snmpConfig',
  AdaptorConfiguration = 'com.hp.cdm.service.ioConfig.version.2.resource.usb_adapterConfig',
  SystemConfiguration = 'com.hp.cdm.service.security.version.1.resource.systemConfig',
  FWUpdateConfiguration = 'com.hp.cdm.service.firmwareUpdate.version.2.resource.configuration',
  FaxReceiveConfiguration = 'com.hp.cdm.service.faxReceive.version.1.resource.configuration',
  FaxSendConfiguration = 'com.hp.cdm.service.fax.version.1.resource.sendConfiguration',
  FTPPrintingConfiguration = 'com.hp.cdm.service.network.version.1.resource.printServices',
  DeviceFirmwareConfiguration = 'com.hp.cdm.service.firmwareUpdate.version.1.resource.autoUpdateConfiguration',
  EwsConfiguration = 'com.hp.cdm.service.ews.version.1.resource.configuration',
  LDAPConfiguration = 'com.hp.cdm.service.security.version.1.resource.ldapConfig',
  PrintConfiguration = 'com.hp.cdm.service.print.version.2.resource.configuration',
  DiscoveryServices = 'com.hp.cdm.service.network.version.1.resource.discoveryServices',
  NameResolverServices = 'com.hp.cdm.service.network.version.1.resource.nameResolverServices',
  UsbHostConfiguration = 'com.hp.cdm.service.usbHost.version.1.resource.configuration',
  ControlPanelAccess = 'com.hp.cdm.service.security.version.1.resource.controlPanelAccess',
  ServiceUserConfiguration = 'com.hp.cdm.service.security.version.1.resource.serviceUserConfig',
  DeviceAdapterConfiguration = 'com.hp.cdm.service.ioConfig.version.2.resource.adapterConfigs',
  SystemResourceConfiguration = 'com.hp.cdm.service.system.version.1.resource.configuration',
  DeviceAdminConfig = 'com.hp.cdm.service.security.version.1.resource.deviceAdminConfig',
  SecurityAccountPolicy = 'com.hp.cdm.service.security.version.1.resource.accountPolicy',
  ClockConfiguration = 'com.hp.cdm.service.clock.version.1.resource.configuration',
  PowerConfiguration = 'com.hp.cdm.service.power.version.1.resource.configuration',
  JobTicketPrint = 'com.hp.cdm.service.jobTicket.version.1.resource.receiveFaxConfigurationDefaults',
  NetworkScanServices = 'com.hp.cdm.service.network.version.1.resource.networkScanServices',
  ConfigPrivate = 'com.hp.cdm.service.supply.version.1.resource.configPrivate',
  ControlPanelResource = 'com.hp.cdm.service.controlPanel.version.1.resource.configuration',
  WorkPathEnablement = 'com.hp.cdm.service.workPath.version.1.resource.configuration',
  BleConfiguration = 'com.hp.cdm.service.ble.version.1.resource.bleConfiguration',
  EmailAddressAndMessageSetting = 'com.hp.cdm.service.jobTicket.version.1.resource.scanEmailConfigurationDefaults',
  OutgoingServerConfiguration = 'com.hp.cdm.email.1.resource.smtpServers',
  ScanDestinationConfiguration = 'com.hp.cdm.service.scan.version.1.resource.destinationConfig',
  PrintConfigurationDefault = 'com.hp.cdm.service.jobTicket.version.1.resource.printConfigurationDefaults',
  MockDefault = 'com.hp.cdm.service.mock.version.1.resource.mockSettings',
  OutgoingServer = 'com.hp.cdm.service.email.version.1.resource.smtpServers',
  FleetProxyLegacyAttributes = 'com.hp.cdm.service.fleetProxy.version.1.resource.legacyAttributes',
  Eth0_DeviceAdapterConfiguration = 'com.hp.cdm.service.ioConfig.version.2.resource.eth0_adapterConfig',
  WifiDirectConfiguration = 'com.hp.cdm.service.ioConfig.version.2.resource.wifiDirectConfig',
  SolutionManagerConfiguration = 'com.hp.cdm.service.solutionManager.version.1.resource.configuration',
  SleepConfiguration = 'com.hp.cdm.service.power.version.1.resource.sleepConfiguration',
  TrayAdmin = 'com.hp.cdm.service.media.version.1.resource.configuration',
  StoredDataPinProtection = 'com.hp.cdm.service.storeJobManagement.version.1.resource.configuration',
  ColorCopyMode_Mock = 'com.hp.cdm.service.mock.version.1.resource.mockColorCopyMode',
  CopyJobBuild_Mock = 'com.hp.cdm.service.jobTicket.version.1.resource.copyConfigurationDefaults',
  HP_WebServices = 'com.hp.cdm.service.cloudServices.version.1.resource.avatarRegistration',
  EWS_Config = 'com.hp.cdm.service.ews.version.1.resource.configuration',
  AccesControlDefault = 'com.hp.cdm.service.security.version.1.resource.authConfig',
  PermissionSets = 'com.hp.cdm.service.security.version.1.resource.rbacConfig'
}
